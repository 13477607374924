import 'flickity/dist/flickity.pkgd.js';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // Check if a specific id is in view and fire the CountUp Function
  },
};